import { SentryError } from '@ifixit/sentry';
import type {
   AddCartLinesMutation,
   AddCartLinesMutationVariables,
   GetCartQueryVariables,
   RemoveCartLinesMutationVariables,
   StorefrontClient,
   UpdateCartLinesMutationVariables,
} from '@ifixit/shopify-storefront-client';

export async function getCart(client: StorefrontClient, { cartId }: GetCartQueryVariables) {
   const { cart } = await client.getCart({ cartId });
   return cart ?? null;
}

export async function addToCart(
   client: StorefrontClient,
   { cartId, lines }: AddCartLinesMutationVariables
) {
   const { cartLinesAdd } = await client.addCartLines({ cartId, lines });
   if (cartLinesAdd && cartLinesAdd.userErrors.length > 0) {
      console.error(cartLinesAdd.userErrors);
   }

   if (!cartLinesAdd?.cart) {
      throw new SentryError('Failed to add to cart', { extra: { cartId, lines } });
   }

   return withSeparatedErrors(cartLinesAdd);
}

export async function updateCartLines(
   client: StorefrontClient,
   { cartId, lines }: UpdateCartLinesMutationVariables
) {
   const { cartLinesUpdate } = await client.updateCartLines({ cartId, lines });
   if (cartLinesUpdate && cartLinesUpdate.userErrors.length > 0) {
      console.error(cartLinesUpdate.userErrors);
   }

   if (!cartLinesUpdate?.cart) {
      throw new SentryError('Failed to update cart lines', { extra: { cartId, lines } });
   }

   return withSeparatedErrors(cartLinesUpdate);
}

export async function removeCartLines(
   client: StorefrontClient,
   { cartId, lineIds }: RemoveCartLinesMutationVariables
) {
   const { cartLinesRemove } = await client.removeCartLines({ cartId, lineIds });
   if (cartLinesRemove && cartLinesRemove.userErrors.length > 0) {
      console.error(cartLinesRemove.userErrors);
   }

   if (!cartLinesRemove?.cart) {
      throw new SentryError('Failed to remove cart lines', { extra: { cartId, lineIds } });
   }

   return withSeparatedErrors(cartLinesRemove);
}

type CartUserErrors = NonNullable<AddCartLinesMutation['cartLinesAdd']>['userErrors'];

type PartitionedUserErrors = {
   validationErrors: string[];
   userErrors: CartUserErrors;
};

function withSeparatedErrors<T extends { userErrors: CartUserErrors }>(
   result: T
): T & PartitionedUserErrors {
   const { validationErrors, userErrors: separatedUserErrors } = separateValidationErrors(
      result.userErrors
   );
   return {
      ...result,
      validationErrors,
      userErrors: separatedUserErrors,
   };
}

function separateValidationErrors(userErrors: CartUserErrors): PartitionedUserErrors {
   return userErrors.reduce(
      (acc: PartitionedUserErrors, error) => {
         if (error.code === 'VALIDATION_CUSTOM') {
            acc.validationErrors.push(error.message);
         } else {
            acc.userErrors.push(error);
         }
         return acc;
      },
      { validationErrors: [], userErrors: [] }
   );
}
