import { useAuthenticatedUser, type User } from '@ifixit/auth-sdk';
import { SentryError } from '@ifixit/sentry';
import {
   type CartInput,
   CartWarning,
   type StorefrontClient,
   useShopifyStorefrontClient,
} from '@ifixit/shopify-storefront-client';
import { useCallback } from 'react';
import { useGetCustomerAccessToken } from '../use-get-customer-access-token';
import { clearStoredCustomerAccessToken, updateCartId } from '../../helpers/storage';

type UseCreateCartProps = {
   lines?: {
      merchandiseId: string;
      quantity: number;
   }[];
};
export function useCreateCart() {
   const userQueryResult = useAuthenticatedUser();
   const { client, storeCode } = useShopifyStorefrontClient();
   const getCustomerAccessToken = useGetCustomerAccessToken();
   return useCallback(
      async ({ lines }: UseCreateCartProps = {}) => {
         const user = userQueryResult.data ?? null;
         let cartCreate = await createCart({ client, getCustomerAccessToken, lines, user });
         if (cartCreate?.userErrors.some(error => error.message === 'Customer is invalid')) {
            // If the customer access token is invalid or expired, clear it from
            // storage and try again. Type assertion: we know the user exists.
            clearStoredCustomerAccessToken({ storeCode, user: user! });
            cartCreate = await createCart({ client, getCustomerAccessToken, lines, user });
         }

         const userErrors = cartCreate?.userErrors ?? [];
         const validationErrors: string[] = [];
         const warnings: CartWarning[] = [];
         const cart = cartCreate?.cart;
         const cartId = cart?.id;

         if (!cart || !cartId) {
            throw new SentryError('Failed to create cart', { extra: { lines } });
         }

         updateCartId({ cartId, storeCode, userId: user?.id ?? null });
         return { cart, userErrors, validationErrors, warnings };
      },
      [client, getCustomerAccessToken, storeCode, userQueryResult]
   );
}

async function createCart({
   client,
   getCustomerAccessToken,
   lines,
   user,
}: {
   client: StorefrontClient;
   getCustomerAccessToken: ReturnType<typeof useGetCustomerAccessToken>;
   lines: UseCreateCartProps['lines'];
   user: User | null;
}) {
   const customerAccessToken = user ? await getCustomerAccessToken(user) : null;
   const input: CartInput = {
      buyerIdentity: { customerAccessToken: customerAccessToken?.accessToken },
      lines,
   };
   const { cartCreate } = await client.createCart({ input });
   return cartCreate;
}
