import {
   convertCartLineItemsToAnalyticsItem,
   trackInAnalyticsAddToCart,
   trackPiwikCartUpdate,
} from '@ifixit/analytics';
import {
   CartWarning,
   type DisplayableError,
   StorefrontClient,
   useShopifyStorefrontClient,
} from '@ifixit/shopify-storefront-client';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import {
   convertAddToCartInputToAnalyticsItemEvent,
   trackAnalyticsShopifyAddToCart,
} from '../../helpers/analytics';
import { addToCart, getCart, updateCartLines } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import type { Cart, CartLineItem } from '../../types';
import { CART_MUTATION_KEY, cartKeys } from '../../utils';
import { useCart, useCartToasts } from './use-cart';
import { useCreateCart } from './use-create-cart';

export type AddToCartDescriptor =
   | 'PDP Main'
   | 'PDP Floating Bar'
   | 'Cart Page Cross Sell'
   | 'Cart Page Merch'
   | 'Cart Drawer Cross Sell'
   | 'Cart Drawer Merch'
   | 'Frequently Bought Together'
   | 'Guide Products'
   | 'Guide Step'
   | 'Answers Product Ad'
   | 'Buy it Again'
   | 'Cart Permalink';

type AddToCartAnalytics =
   | {
        descriptor: Exclude<AddToCartDescriptor, 'Frequently Bought Together'>;
        localeCode?: string;
     }
   | {
        descriptor: Extract<AddToCartDescriptor, 'Frequently Bought Together'>;
        currentItemcode: string;
        localeCode?: string;
     };

export type AddToCartInput = {
   lines: CartLineItem[];
   analytics: AddToCartAnalytics;
};

const META_TYPE = 'add-to-cart';

export function useAddToCart() {
   const cart = useCart().data;
   const createCart = useCreateCart();
   const isMutating = useIsMutating({
      mutationKey: CART_MUTATION_KEY,
      predicate: mutation => mutation.meta?.type !== META_TYPE,
   });
   const queryClient = useQueryClient();
   const { client: storefrontClient, currencyCode } = useShopifyStorefrontClient();
   const { showCartWarnings, showErrorToast } = useCartToasts();
   const mutation = useMutation({
      mutationKey: CART_MUTATION_KEY,
      meta: { type: META_TYPE },
      mutationFn: async ({ lines }: AddToCartInput) => {
         if (lines.length === 0 || cart == null) {
            return buildIfixitCart({ cart: null, fallbackCurrencyCode: currencyCode });
         }

         let newCart = null;
         let userErrors: DisplayableError[] = [];
         let validationErrors: string[] = [];
         let warnings: CartWarning[] = [];

         if (cart.shopifyCartId) {
            const result = await performAddToCart({
               cartId: cart.shopifyCartId,
               client: storefrontClient,
               lines,
            });
            newCart = result.cart;
            userErrors = result.userErrors;
            validationErrors = result.validationErrors;
            warnings = result.warnings;
         }

         if (!cart.shopifyCartId || !newCart) {
            const result = await createCart({
               lines: lines.map(({ quantity, shopifyVariantId }) => ({
                  merchandiseId: shopifyVariantId,
                  quantity,
               })),
            });
            newCart = result.cart;
            userErrors = result.userErrors;
            validationErrors = result.validationErrors;
            warnings = result.warnings;
         }

         return buildIfixitCart({
            cart: newCart,
            fallbackCurrencyCode: currencyCode,
            userErrors,
            validationErrors,
            warnings,
         });
      },
      onMutate: async ({ lines }: AddToCartInput) => {
         await queryClient.cancelQueries({ queryKey: cartKeys.cart });
         window.onbeforeunload = () =>
            "Some products are being added to the cart. Are you sure you'd like to cancel?";

         const previousCart = queryClient.getQueryData<Cart>(cartKeys.cart);

         queryClient.setQueryData<Cart | undefined>(cartKeys.cart, currentCart => {
            if (currentCart == null) {
               return currentCart;
            }
            const updatedCart = lines.reduce((cart, line) => addLineItem(cart, line), currentCart);

            return updatedCart;
         });

         return { previousCart };
      },
      onError: (error, variables, context) => {
         queryClient.setQueryData<Cart | undefined>(cartKeys.cart, context?.previousCart);
         showErrorToast();
      },
      onSuccess: (cart, variables) => {
         showCartWarnings(cart);

         trackAnalyticsShopifyAddToCart(variables);
         const event = convertAddToCartInputToAnalyticsItemEvent(variables);
         trackInAnalyticsAddToCart(event);
         trackPiwikCartUpdate({
            items: convertCartLineItemsToAnalyticsItem(cart.lineItems),
            value: Number(cart.totals.price.amount),
            currency: cart.totals.price.currencyCode,
            localeCode: variables.analytics.localeCode,
         });
      },
      onSettled: () => {
         window.onbeforeunload = () => {};
         return queryClient.invalidateQueries({ queryKey: cartKeys.cart });
      },
   });
   return { addToCart: mutation, enabled: cart != null && !isMutating };
}

export async function performAddToCart({
   cartId,
   client,
   lines,
}: { client: StorefrontClient; cartId: string; lines: CartLineItem[] }) {
   const cart = await getCart(client, { cartId });
   const lineEdges = cart?.lines.edges ?? [];
   const linesToUpdate =
      cart == null
         ? []
         : lines
              .map(line => {
                 const existingLine = lineEdges.find(
                    ({ node }) => node.merchandise.id === line.shopifyVariantId
                 )?.node;
                 const existingQuantity = existingLine?.quantity ?? 0;
                 return existingLine?.id
                    ? {
                         id: existingLine.id,
                         merchandiseId: line.shopifyVariantId,
                         quantity: existingQuantity + line.quantity,
                      }
                    : null;
              })
              .filter(<T,>(line: T | null): line is T => line != null);
   const linesToAdd = lines
      .filter(line => !lineEdges.some(({ node }) => node.merchandise.id === line.shopifyVariantId))
      .map(({ shopifyVariantId, quantity }) => ({
         merchandiseId: shopifyVariantId,
         quantity,
      }));

   let newCart = cart;
   let userErrors: DisplayableError[] = [];
   let validationErrors: string[] = [];
   let warnings: CartWarning[] = [];

   if (linesToUpdate.length > 0) {
      const result = await updateCartLines(client, { cartId, lines: linesToUpdate });
      newCart = result.cart ?? null;
      userErrors = [...userErrors, ...result.userErrors];
      validationErrors = [...validationErrors, ...result.validationErrors];
      warnings = [...warnings, ...result.warnings];
   }

   if (linesToAdd.length > 0) {
      const result = await addToCart(client, { cartId, lines: linesToAdd });
      newCart = result.cart ?? null;
      userErrors = [...userErrors, ...result.userErrors];
      validationErrors = [...validationErrors, ...result.validationErrors];
      warnings = [...warnings, ...result.warnings];
   }

   return { cart: newCart, userErrors, validationErrors, warnings };
}

function addLineItem(cart: Cart, inputLineItem: CartLineItem): Cart {
   const currentLineItemIndex = cart.lineItems.findIndex(
      item => item.itemcode === inputLineItem.itemcode
   );
   const isAlreadyInCart = currentLineItemIndex !== -1;
   const updatedLineItems = [...cart.lineItems];
   if (isAlreadyInCart) {
      const currentLineItem = cart.lineItems[currentLineItemIndex];
      const updatedQuantity = currentLineItem.quantity + inputLineItem.quantity;
      updatedLineItems.splice(currentLineItemIndex, 1, {
         ...currentLineItem,
         quantity: updatedQuantity,
      });
   } else {
      // Match Shopify's behavior of prependng new items to the cart
      updatedLineItems.unshift(inputLineItem);
   }
   const updateTotalPrice = Math.max(
      Number(cart.totals.price.amount) +
         inputLineItem.quantity * Number(inputLineItem.price.amount),
      0
   ).toFixed(2);
   return {
      ...cart,
      hasItemsInCart: true,
      isEmpty: false,
      lineItems: updatedLineItems,
      totals: {
         ...cart.totals,
         price: {
            ...cart.totals.price,
            amount: updateTotalPrice,
         },
         itemsCount: cart.totals.itemsCount + inputLineItem.quantity,
      },
   };
}
